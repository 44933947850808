import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import {
  calculateSubTotalPrice,
  calculateTax,
  calculateTotalPrice,
} from "../../utils/priceCalculation";

const PricingTable: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const order = useAppSelector((rootState) => rootState.order);

  const country = order?.contactInfo?.companyCountry?.toUpperCase() ?? "";

  const defaultTaxString = process.env.REACT_APP_TAX_RATE || "8.1";
  const defaultTaxValue = parseFloat(defaultTaxString);
  const taxRate = country === "CH" ? defaultTaxValue : 0.0;

  const subTotal = calculateSubTotalPrice(
    order.product?.basePrice,
    order.discountCode?.discount
  );

  const taxAmount = calculateTax(
    order.product?.basePrice,
    order.discountCode?.discount,
    taxRate
  );

  const total = calculateTotalPrice(
    order.product?.basePrice,
    order.discountCode?.discount,
    taxRate
  );

  const taxLabel =
    taxRate > 0 ? `${t("misc.taxRate")} ${taxRate}%` : t("order.noTaxLabel");

  return (
    <Table bordered responsive size="md" className="mt-3 col-12 col-md-4">
      <thead>
        <tr>
          <th></th>
          <th className="text-end">{t("misc.price")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {t("misc.product")}: {order.product?.name}
          </td>
          <td className="text-end">
            CHF {order.product?.basePrice.toFixed(2)}
          </td>
        </tr>
        {order.discountCode?.discount && (
          <>
            <tr>
              <td className="fst-italic">
                {t("order.discount.discountPlaceholder")}:{" "}
                {order.discountCode?.code} ({order.discountCode?.name})
              </td>
              <td className="text-end fst-italic">
                -{order.discountCode?.discount.toFixed(2)} %
              </td>
            </tr>
            <tr>
              <td>{t("misc.subtotal")}</td>
              <td className="text-end">CHF {subTotal}</td>
            </tr>
          </>
        )}
        <tr>
          <td>{taxLabel}</td>
          {taxRate > 0 ? (
            <td className="text-end">CHF {taxAmount}</td>
          ) : (
            <td className="text-end">CHF 0.00</td>
          )}
        </tr>
      </tbody>
      <tfoot>
        <tr className="bg-lightcolor fw-bold">
          <td>{t("misc.total")}</td>
          <td className="text-end">CHF {total}</td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default PricingTable;
